@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Reg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Sbold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Proxima Nova";
}

html {
  scroll-behavior: "smooth";
}

html ::-webkit-scrollbar {
  width: 6px !important;
  scroll-behavior: smooth !important;
  margin: -1px;
}
html ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 3px !important;
}
html ::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  border-radius: 3px !important;
}
html ::-webkit-scrollbar-corner {
  background-color: transparent;
}

.custom-image-viewer.single #ReactSimpleImageViewer span:not(:first-child) {
  display: none;
}

.react-simple-image-viewer__modal {
  z-index: 999;
  background: rgba(0, 0, 0, 0.9);
}

.react-simple-image-viewer__close {
  opacity: 0.5;
}
